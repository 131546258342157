import axios from "axios";
import React, { useState, useEffect } from 'react';
import moment from 'moment';

export default function RelevantNews(props: any) {
    const [allAnnouncements, setallAnnouncements]: any = useState([]);
    const newsWebpartId = props.newsItem[0].id
    //const GetserverUrl = 'http://localhost:4000/api/getDataFilterSmartPageId';
    //const GetserverUrl = 'http://localhost:4000/api/getData';
    const GetserverUrl = 'https://eventservers.onrender.com/api/getData';
    useEffect(() => {
        getNewsListData();
    }, [])

    const getNewsListData = async () => {
        const tableName = 'Announcements';
        try {
            const response = await axios.get(`${GetserverUrl}?table=${tableName}`);
            // Parse SmartPagesId values from JSON strings to arrays
            const parsedData = response.data.map((item: any) => {
                let smartPagesIdArray = [];
                try {
                    // Check if SmartPagesId is a non-empty JSON string
                    if (item.SmartPagesId && item.SmartPagesId.trim() !== '') {
                        smartPagesIdArray = JSON.parse(item.SmartPagesId);
                    }
                } catch (error) {
                    console.error('Error parsing SmartPagesId:', error);
                    // Handle the error, such as setting to an empty array
                }

                return {
                    ...item,
                    SmartPagesId: smartPagesIdArray
                };
            });
            parsedData.map((item: any) => {
                if (item?.PublishingDate != null && item?.PublishingDate != undefined) {
                    item.PublishingDate = moment(item?.PublishingDate, "MMM DD YYYY").format("DD MMM YYYY");
                }
                return item; // Return the modified item
            });

            // Filter data based on newsWebpartId
            const eventdata = parsedData.filter((item: any) => {
                // Check if SmartPagesId is an array and includes newsWebpartId
                if (Array.isArray(item?.SmartPagesId)) {
                    return item.SmartPagesId.includes(parseInt(newsWebpartId));
                } else {
                    // If SmartPagesId is not an array, check if it matches newsWebpartId
                    return item.SmartPagesId.includes(parseInt(newsWebpartId));
                }
            });
            if (eventdata && eventdata.length > 0) {
                props.showwebpart()
            }
            setallAnnouncements(eventdata);
            console.log(allAnnouncements);
        } catch (error: any) {
            console.error(error);
        };
    };
    return (
        <>
            {allAnnouncements && allAnnouncements.length > 0 && (
                <div className="container">
                    <div className="panel panel-default">
                        <div className="panel-heading">Relevant News</div>
                        {allAnnouncements.map((news: any) => (
                            <div key={news.Id} className="panel-body">
                                <div className="entry-meta">
                                    <span>{news.PublishingDate}</span>
                                </div>
                                <div>{news.Title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );


}
